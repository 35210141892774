
body {
  overflow: hidden;
}
:root {
  /* COLORS */
  --BGCOLOR: #0F172A;
  --BORDER-COLOR: #FFF;
  --COLOR: #FFF;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: whitesmoke;
  --LINK-HOVER: rgba(245, 245, 245, 0.9);
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: #FFF;
  --TABLE-COLOR: #000;
  --INPUT-INVALID: #F00;
}


.component__layout__logo img {
width: 200px;
height: auto;
}





.component__layout__content {
flex: 1;
padding: 20px;
}

.home-page {

  display: flex;
  justify-content: center; /* Center the columns horizontally */
  padding-top: 50px;
  height: 100vh;
}
.columns-container {
  display: flex;
  align-items: center;
  justify-content: center; /* Center columns horizontally */
  width: 100%; /* Adjust the width as needed */

}
.component__layout__nav-buttons {
  display: flex;
  flex-direction: column;

  margin-top: 20px;

}
.component__layout__nav-buttons button {
  display: flex;
  font-size: 100%;
  align-items: center;
  background: none;
  border: none;
  color: rgb(10, 1, 1);
  cursor: pointer;
  margin: 10px 0;
}
.component__layout__navbar {
  display: flex;
  flex-direction: column;
  color: white;
  padding-top: 50px;
  margin-bottom: auto;
}
.left-column {
  

  display: flex;
  flex-direction: column;
  justify-content: center; /* Center the columns horizontally */
  align-items: center; /* Center the columns vertically */
  width: 300px;


  margin-left: auto; /* Remove margin */
  height: 100%;

}
.main-column {
  overflow-y: auto; /* Add this line for vertical scrolling */

 
  width: 700px;
  flex-shrink: 0; /* Prevent shrinking */
  height: 100%;
  border-right: 1px solid #ccc; /* Add the grey line */
  flex-direction: column;
  align-items: center;
}
.right-column {
  display: flex;
  height: 100%;
  width: 300px;
  flex-shrink: 0; /* Prevent shrinking */
  margin-right: auto;
  flex-direction: column;
  justify-content: center;

  }
.event-list {

flex-direction: row;
margin: 10 auto;
overflow-y: auto; /* Add this line for vertical scrolling */
max-height: calc(100vh - 40px); /* Adjust based on your layout */
display: flex;
flex-direction: column;
align-items: center;

}

.public-events {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  align-items: center;
}

.search-filters {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  padding: 10px;
}
.toggle-container {
display: flex;
align-items: center;
gap: 10px;
margin-bottom: 30px;
background-color: #cdc5c5;
border-radius: 50px;
padding: 10px;



}




.toggle-button,
.filter-button {
padding: 8px 16px;
border: none;
background-color: transparent;
color: #2a2929;
cursor: pointer;
font-weight: bold;
border-radius: 20px;
}

.toggle-button.active,
.filter-button.active {
background-color: #474343;
color: white;
}


.search-container {
display: flex;
align-items: center;
border: 1px solid #ccc;
border-radius: 5px;
padding: 5px;
width: 100%;
}

.search-container input {
  display: flex;
  border: none;
  outline: none;
  margin-left: 5px;
  width: 100%;
}

.filter-container {
  margin-top: 100px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  }

.event {
  display: flex;
  border: 1px solid #ccc;
  width: 90%;
  padding: 20px;
  border-radius: 10px;
  background-color: #f2f0f09a;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  margin-bottom: 10px ;
}

.event img {
  height: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 10px;
}

.event h3 {
  margin-top: 0;

}

.invited-list {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}
.invited-img-container {
    width: 30px;
    height: 30px; /* Set the same value as width for a perfect circle */
    border-radius: 50%; /* Use 50% for a circle */
    border: 2px solid #ccc; /* Add a border */
    overflow: hidden; /* Hide any overflow content */
}
.event-mini-img-container{
  display: flex;
  flex-direction: column;
  display: flex;
  max-width: 100%;
  max-height: 1000px;
  justify-content: center;
  align-items: center;
}

.eventmini-user{
  display: flex;
  flex-direction: row;
  align-items: center;
  
  
  
}
.event-about{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  width: 100%;
}
.show-more-link {
  color: #3498db; /* Link color */
  cursor: pointer;
  margin-left: 5px;
}

.show-more-link:hover {
  text-decoration: underline;
}

.owner-event-mini-container{
  display: flex;
    width:50px;
    height: 50px; /* Set the same value as width for a perfect circle */
    border-radius: 50%; /* Use 50% for a circle */
    overflow: hidden; /* Hide any overflow content */
   
}

.eventmini-user a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.eventmini-user p{
  margin-left: 10px;
}

.event-image-comment-container {
  display: flex;
}
.event-image-comments-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
}
.event-image {
  max-width: 100%;
  height: auto;
  object-fit: cover; /* To ensure the image maintains its aspect ratio */
}

.commentsMiniContainer{
  flex: 1;
  padding-left: 10px; /* Add spacing between image and comments */
  overflow-y: auto; /* Add scrollbar if comments overflow vertically */
}
.commentsMiniContainer-main{
  display: flex;
  flex-direction: column;
}
.commentMini {
  display: flex;
  flex-direction: column;

  padding: 10px; /* Add some spacing */
}

.commentOwner {
  display: flex;
  align-items: center;
  margin-bottom: 5px; /* Add some spacing between owner and content */
}

.commentOwner img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px; /* Add spacing between image and text */
}

.commentOwner p {
  margin: 0; /* Reset default margin */
  font-weight: bold;

}

.errmsg {
  display: inline-block;
  background-color: var(--TABLE-BGCOLOR);
  color: var(--ERROR);
  padding: 0.25em;
  margin-bottom: 0.5em;
}




.logbutton {
  margin: 10px;
  padding: 5px 15px;
  background-color: #1DA1F2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.logbutton:hover {
  background-color: #0D91E5;
}
.user-container {
  display: flex;
  flex-direction: column;
  width:500px;
  text-align: left;
  align-items: center;
  margin: 20px;
}

.user-profile {
  display: flex;
 
  flex-direction: row;
  margin-right: auto;
  align-items: center;
  margin-top: 10% ;
  margin-bottom: 20px;
}
.user-profile h2{
  margin-left: 20px;
}
.user-profile img {
  max-width: 100px;
  border-radius: 50%;

}

.user-about {
  display: flex;
  flex-direction: column;
  margin-right: auto;

  margin-bottom: 20px;
}

.user-about p {
  display: flex;
  text-align: left;
  max-width: 500px;
  margin: 0 auto;
  
}
.user-info-container {
  display: flex;  
  flex-direction: column;
  margin-right: auto;
}
.user-events {
  text-align: center;
}


.form {
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.form__title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form__label {
  display: block;
  margin-bottom: 5px;
}

.form__input {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
}



.icon-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.errmsg {
  color: #e74c3c;
  margin-bottom: 10px;
}

.nowrap {
  white-space: nowrap;
}

.form__select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
}


/* Additional styling for the selected options in the multi-select */
.form__select option:checked {
  background-color: #3498db;
  color: #fff;
}



.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 90%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: #555;
}

.modal-body {
  margin-top: 10px;
}

/* ... (rest of your CSS) */

/* Add this CSS for modals */
.my-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Make sure modals appear above other content */
}

.signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.signup-text {
  margin-bottom: 5px;
}

.signup-link {
  color: #3498db;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

.signup-link:hover {
  text-decoration: underline;
}


.errmsg {
  display: inline-block;
  background-color: var(--TABLE-BGCOLOR);
  color: var(--ERROR);
  padding: 0.25em;
  margin-bottom: 0.5em;
}

.errmsg a:any-link {
  color: var(--ERROR);
  text-decoration: underline;
}

.form__input--incomplete {
  border: 1px solid var(--INPUT-INVALID);
  outline: 1px solid var(--INPUT-INVALID);
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-bottom: 10px;
}





.offscreen{
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.Mini-user-login{
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 20px;

}
.Mini-user-login div{
  display: flex;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 20px;

}

.Mini-user-login p {
  color: black;
  margin-left: 10px;
  
}

.profile-image {
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: filter 0.3s;
  border-radius: 50%;
  overflow: hidden;
}

.profile-image img {
  object-fit: cover;
}

.editable {
  &:hover {
    filter: brightness(0.7);
  }

  .edit-icon {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 5px;
    color: white;
    cursor: pointer;
  }
}

.user-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}

.user-modal.open {
  display: flex;
}

.user-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.user-modal-content h2 {
  margin-bottom: 10px;
}

.user-modal-content input[type="file"] {
  margin: 10px 0;
}

.user-modal-content button {
  margin: 5px;
}


.create-new-event {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.plus-button {
  border-radius: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s;
}

.plus-icon {
  font-size: 24px;
  margin-right: 6px;
  transition: color 0.3s;
}

.button-text {
  font-size: 14px;
  transition: color 0.3s;
}

.plus-button:hover {
  background-color: #e0e0e0; /* Grey color */
}

.plus-button:hover .plus-icon {
  color: #333; /* Darker grey color for icon on hover */
}

.plus-button:hover .button-text {
  color: #333; /* Darker grey color for text on hover */
}


.user-search-results {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1000;
}

.user-search-result {
  display: flex;
  align-items: center;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.user-search-result:hover {
  background-color: #f5f5f5;
}

.user-search-result img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-search-result p {
  margin: 0;
}

/* Adjust styles as needed */


.event-creation-form {
  flex: display;
  width: 500px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.event-creation-form h2 {
  margin-bottom: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group input[type="file"],
.form-group input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.form-group input[type="file"] {
  padding: 5px;
}

.form-group input[type="text"]:focus,
.form-group input[type="date"]:focus,
.form-group input[type="file"]:focus,
.form-group input[type="text"]:focus {
  border-color: #007bff;
}

.form-group input[type="text"][pattern]::placeholder {
  color: #999;
}

.form-group input[type="text"][pattern]:invalid {
  border-color: #dc3545;
}

.form-group input[type="text"][pattern]:valid {
  border-color: #28a745;
}

.form-group button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
}

/* Event Preview */
.event-preview {
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  max-width: 400px;
}

.event-preview h3 {
  margin-bottom: 10px;
}

/* EventMini */
.event-mini {
  display: flex;
  border: 1px solid #ccc;
  padding: 10px;
}

.event-mini img {
  max-width: 100px;
  height: auto;
  margin-right: 10px;
}

.event-mini-content {
  flex: 1;
}

.user-selection-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-selection-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-width: 400px;
}

.user-selection-content h3 {
  margin-bottom: 15px;
}

.user-list {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.user-list-item {
  display: flex;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.user-list-item img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.user-list-item p {
  flex: 1;
  margin: 0;
}

.user-list-item button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.invited-users {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.invited-user {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.invited-user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.invited-user p {
  margin: 0;
  margin-right: 5px;
}

.invited-user button {
  padding: 3px 5px;
  font-size: 12px;
}
.countdown-bar {
  position: relative;
  width: 100%;
  height: 20px;
  overflow: hidden;
  background-color: #c5c0c0;
  border-radius: 10px;

}

bar-container {
  position: absolute;
  width: 100%;
  height: 100%;

}
.bar {
  width: 150%;
  height: 100%;
  position: absolute;
  background-image: linear-gradient(to right, #f9d423 0%, #ff4e50 100%);
  border-radius: 10px;
}

.progress-text{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 20px;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
}
.friends-container{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 500px;
  height: 80%;
}
.friends-container-main{
  display: flex;
  width: 100%;
}
.friends-container h2{

  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;

}

.friends-container img{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.friends-container button{
  height: 50%;
  
  

}
.friends-container h3{
 margin-left: 20px;
}
.friends-container-buttons{
  display: flex;
  margin-left: auto;
  align-items: center;
  margin-right: 20px;
}



.error-message {

  margin-top: 10px;
  text-align: center;
}

.error-text {
  color: red;
  font-size: 14px;
}
.no-events{
  
}